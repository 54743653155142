/* contact.css */

.contact-container {
  font-family: Roboto;
  padding: 45px 30px;
  color: #fff;
}

.contact-text-box {
  align-items: center;
  text-align: center;
}

.contact-text-box h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 0px;
}

.contact-text-box h3 {
  text-decoration: underline;
}

.contact-card {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: black;
  border-radius: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.map-section {
  grid-column: span 3; /* Ensure the map covers the full width */
}

.social-links ul {
  list-style: none;
  padding: 0;
}

.social-links li {
  margin-bottom: 10px;
}
