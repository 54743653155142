.stack-container {
  font-family: Roboto;
  padding: 45px 30px;
  color: #fff;
}

.stack-text-box {
  align-items: center;
  text-align: center;
}

.stack-text-box h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
}

.stack-unique-keyword {
  font-size: 64px;
  text-decoration: underline;
}

.stack-table {
  margin-top: 50px;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  position: relative; /* Add this line for stacking context */
  padding: 10px;
  border: none;
  height: 180px; /* Set a fixed height for the table cells */
  overflow: hidden; /* Hide overflowing content */
}

.framework-img {
  transition: filter 0.1s ease-in-out;
}

.framework-img:hover {
  filter: brightness(200%) drop-shadow(0 0 0.75rem #ffff); /* Adjust the glow effect as needed */
}

