.about-container {
  font-family: Roboto;
  padding: 100px 30px;
  color: #fff;
  display: flex;
  flex-direction: row;
}

.about-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: 550px;
  --c: #fff; /* the border color */
  --b: 1px; /* the border thickness*/
  --g: 5px; /* the gap on hover */
  --_g: #0000 25%, var(--c) 0;
  background: conic-gradient(
        from 180deg at top var(--b) right var(--b),
        var(--_g)
      )
      var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0
      var(--_i, 200%) / var(--_i, var(--b)) 200% no-repeat;
  transition: 0.3s, background-position 0.3s 0.3s;
  filter: blur(1.5rem);
}

.about-img-box:hover {
  --_i: 100%;
  transition: 0.3s, background-size 0.3s 0.3s;
  filter: blur(0);
}

.about-img {
  height: 500px;
}

.about-text-box {
  padding-left: 200px;
}

.about-myself-box {
  width: 800px;
  text-align: justify;
}

.about-text-box h1 {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: underline;
}

.about-text-box p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.skill-table {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
}

.row {
  display: flex;
  justify-content: space-between; /* Create space between columns */
  width: 100%; /* Ensure rows take the full width */
  margin-bottom: 10px; /* Adjust the spacing between rows */
}

.cell {
  margin: 5px 20px;
  text-align: center;
  flex: 1; /* Allow cells to grow and take up equal space */
  padding: 20px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Optional: Add borders for cell separation */
  border-radius: 30px;
  transition: all 0.8s ease;
}

.cell:hover {
  border: 1px solid #fff; /* Optional: Add borders for cell separation */
  background-color: #fff;
  color: #263138;
  font-weight: bold;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Add a shadow to create a 3D effect */
  transform: scale(1.05); /* Apply a scale transform to make it appear raised */
}
